// No arquivo de estilos
import styled from "styled-components";
import theme from "../../styles/theme";
import etecta from "../../assets/walpaper2.jpg";

export const Container = styled.div`
  display: flex;
  background-color: ${theme.colors.test};
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-top: 13%;

  @media (max-width: 700px) {
    flex-direction: column; /* Empilha ContentLeft e ContentRight verticalmente */
    padding-top: 5%; /* Ajusta o padding conforme necessário para mobile */
  }
`;

export const ContentLeft = styled.div`
  width: 35vw;
  height: 50%;
  margin-top: -20%;
  margin-left: 6%;

  @media (max-width: 700px) {
    margin-top: 30px; /* Ajusta o margin para mobile */
    height: 40vh; /* Ajusta a altura para mobile */
    width: 90vw; /* Ajusta a largura para mobile */
    margin-left: 0; /* Remove a margem esquerda no mobile */
  }
`;

export const StyledButton = styled.button`
  margin-top: 2%;
  padding: 10px 20px; /* Ajusta o padding para dar espaço interno */
  background-color: #66bb6a; /* Cor de fundo verde */
  color: white; /* Cor do texto branco */
  border: none; /* Remove a borda */
  border-radius: 5px; /* Borda arredondada */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  cursor: pointer; /* Indica que é clicável */
  font-size: 16px; /* Tamanho da fonte */

  @media (max-width: 700px) {
    margin-top: 10%; /* Ajusta a margem responsiva */
    margin-left: 5%;
    padding: 8px 16px; /* Padding responsivo */
    font-size: 10px; /* Tamanho da fonte responsivo */
  }
`;

export const ContentRight = styled.div`
  display: flex;
  margin-top: -10%;
  margin-left: 8%;
  background-image: url(${etecta});
  background-repeat: no-repeat;
  background-size: cover;
  width: 50vw;
  height: 90vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    width: 100vw;
    height: 40vh;
    margin-top: 25px;
    margin-left: 0; /* Remove margem esquerda no mobile */
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 1.8rem;
    margin-top: 40%;
  }
`;

export const Subtitle = styled.h2`
  margin-top: 5%; /* Reduz o espaço superior */
  line-height: 1.8;
  font-size: 1.2rem;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 1rem;
    margin-top: 5%; /* Ajusta a margem superior para mobile */
  }
`;
