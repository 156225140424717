/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container, ContentArea, Logo } from "./styles";

function Footer() {
  return (
    <>
      <Container>
        <Logo />

        <ContentArea></ContentArea>
      </Container>
    </>
  );
}

export default Footer;
