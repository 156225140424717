import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.colors.brancogelo};

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const FlexContainer = styled.div``;

export const ContentUp = styled.div`
  width: 90vw;
  height: 50%;
  margin-top: 10%;

  @media (max-width: 700px) {
    margin-top: -30%;
    height: 60vh;
    width: 90vw;
  }
`;

export const StyledButton = styled.button`
  margin-top: 10%;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;

  @media (max-width: 700px) {
    margin-top: 1%;
    padding: 8px 16px;
    font-size: 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Mudança para coluna */
  align-items: center;
  margin-top: 30px;
`;

export const ContentLeft = styled.div`
  width: 90vw; /* Ajustar largura para 90vw */
  height: 100%;
  margin-bottom: 20px; /* Ajustar margem inferior para espaçamento */
  background-color: ${theme.colors.beginho};
  border-radius: 15px;
  margin-top: 90px;
  @media (max-width: 700px) {
    width: 90vw;
    margin-bottom: 10%;
  }
`;

export const ContentRight = styled.div`
  width: 90vw; /* Ajustar largura para 90vw */
  height: 100%;
  background-color: ${theme.colors.beginho};
  border-radius: 15px;

  @media (max-width: 700px) {
    width: 90vw;
    margin-top: 10%;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 1.8rem;
    margin-top: 40%;
  }
`;

export const Subtitle = styled.h2`
  margin-top: 10%;
  line-height: 1.8;
  font-size: 1rem;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 1rem;
    margin-top: 5%;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 1.6em;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SectionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const SectionListItem = styled.li`
  margin-bottom: 12px;
`;

export const SubSection = styled.div`
  margin-top: 20px;
`;

export const SubSectionTitle = styled.h3`
  font-size: 1.2em;
  color: black;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 10px;

  @media (max-width: 700px) {
    margin-top: 20px;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 10px;
`;

export const ItemList = styled.ul`
  list-style-type: circle;
  padding-left: 20px;
`;

export const Item = styled.li`
  margin-bottom: 5px;
`;
