/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, ContentLeft, ContentRight, StyledButton } from "./styles";
import Contact from "../Contact";
import theme from "../../styles/theme";
import Informations from "../Informations";
import TagPage from "../../components/TItlePage";
import { Text, TextHeading } from "../../components/Text/index";

function Home() {
  const handleButtonClick = () => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSd7i48zOJEtglFRuQEcb_j9T-jP5oTwNmNRIGeny_eN1YOx4g/viewform";
  };
  return (
    <>
      <Container id={"home"}>
        <Header />
        <ContentLeft>
          <TextHeading
            size={theme.sizes.rem2}
            size_m={theme.sizes.rem2}
            color={theme.colors.white}
            align={"center"}
          >
            XI ETECTA
          </TextHeading>
          {/* <Text>
            Um evento tradicional trazendo profissionais de toda área da
            avicultura de diversos estados do país para a universidade.
            <br></br>
            Em 2024 o tema central é “Microbioma e Alternativos a
            Antimicrobianos”. Pela importância do tema também haverá eventos
            paralelos para suínos e outros animais de produção e reprodução.
            <br></br>
            Palestrantes renomados de instituições brasileira, francesa,
            colombiana e norte americana participam do XI ETECTA.
          </Text> */}

          <Text>
            O ETECTA é um evento tradicional na UFU trazendo profissionais do
            campo, laboratórios e frigoríficos.
            <br></br>
            Para esse ano, trazemos a edição do XI ETECTA com temas atuais
            relacionados à resistência aos antimicrobianos, microbiota e o uso
            de alternativos aos antimicrobianos.
            <br></br>O evento acontecerá de 1 a 3 de outubro, e será realizado
            na Universidade Federal de Uberlândia. A edição de 2024 traz
            palestrantes que são referencias em suas áreas de conhecimento de
            instituições brasileira, francesa, colombiana e norte americana.
          </Text>

          <StyledButton onClick={handleButtonClick}>
            Inscreva-se aqui
          </StyledButton>
        </ContentLeft>

        <ContentRight></ContentRight>
      </Container>
      <br></br>
      <br></br>
      <TagPage text="Fotos de edições anteriores"> </TagPage>
      <Contact />
      <TagPage text="Nossos Patrocinadores"></TagPage>
      <Informations />
      <Footer />
    </>
  );
}

export default Home;
