import React from "react";
// import Routes from "./routes";
import Home from "./screens/Home";
import Submit from "./screens/Submitting";
import Speech from "./screens/Speeches";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => (
  <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/submissao-de-trabalhos" element={<Submit />} />
        <Route path="/palestrantes" element={<Speech />} />
      </Routes>
    </Router>
  </div>
);

export default App;
