/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  ContentRight,
  ContentLeft,
  SectionList,
  SectionListItem,
  SubSection,
  SubSectionTitle,
  Paragraph,
  ItemList,
  Item,
  ContentWrapper,
} from "./styles";

function Submit() {
  return (
    <>
      <Container id="home">
        <Header />

        <ContentWrapper>
          <ContentLeft>
            <SectionList>
              <SubSectionTitle>Orientações Gerais - Resumo</SubSectionTitle>

              <SectionListItem>
                <strong>1:</strong> Para submeter o resumo pelo menos o autor ou
                um dos coautores precisa ter realizado a inscrição. Ou seja,
                pelo menos um dos membros do resumo deve estar inscrito no
                evento.
              </SectionListItem>

              <SectionListItem>
                <strong> 2:</strong> Os resumos serão submetidos pelo
                apresentador (autor ou coautor) para o email
                etectaufu@gmail.com.
              </SectionListItem>
              <SectionListItem>
                <strong> 3:</strong> Serão aceitos resumos simples das seguintes
                áreas:
                <ItemList>
                  <Item>Eixo 1: Aves</Item>
                  <Item>Eixo 2: Suínos</Item>
                  <Item>Eixo 3: Animais de produção (equinos, bovinos).</Item>
                </ItemList>
              </SectionListItem>
              <SectionListItem>
                <strong> 4:</strong> Cada apresentador poderá enviar no máximo
                02 trabalhos.
              </SectionListItem>
              <SectionListItem>
                <strong> 5:</strong> Não haverá limite de participação em
                trabalhos por um mesmo autor.
              </SectionListItem>
              <SectionListItem>
                <strong> 6:</strong> Não haverá limite do número de coautores
                por resumo.
              </SectionListItem>
              <SectionListItem>
                <strong> 7:</strong> Serão aceitos trabalhos relacionados à área
                de microbiota; resistência aos antimicrobianos e uso de
                alternativos ao uso dos antimicrobianos para melhoria de
                desempenho, para controle de patógenos ou outras ações em
                animais de produção.
              </SectionListItem>
              <SectionListItem>
                <strong> 8:</strong> No momento da submissão será necessário
                preencher o nome completo, afiliação e CPF de todos os
                coautores; salvo para autores estrangeiros.
              </SectionListItem>
              <SectionListItem>
                A: O apresentador definirá no momento da submissão a ordem dos
                autores.
              </SectionListItem>
              <SectionListItem>
                <strong>9:</strong> Serão aceitos apenas trabalhos de pesquisa
                científica. NÃO serão aceitos trabalhos de revisão.
              </SectionListItem>
            </SectionList>

            <SubSection>
              <SubSectionTitle>
                Normas para confecção de resumos
              </SubSectionTitle>
              <SectionList>
                <SectionListItem>
                  <strong> 9:</strong> O Trabalho deverá ser redigido em
                  português, inglês ou espanhol.
                </SectionListItem>
                <SectionListItem>
                  <strong> 10:</strong> O resumo deverá possuir os seguintes
                  itens:
                  <br />
                  <br />
                  <ItemList>
                    <Item>A. Título: máximo 200 caracteres com espaço.</Item>
                    <Item>
                      B. Corpo do resumo: máximo 3.000 caracteres com espaço.
                      <Paragraph>
                        • Não será permitido inserir imagens, gráficos, tabelas
                        ou referências no corpo do texto.
                      </Paragraph>
                    </Item>
                    <Item>
                      C. Palavras-chave: mínimo 3 e máximo 5, com no máximo 50
                      caracteres com espaço;
                    </Item>
                    <Item>
                      D. Agradecimentos (opcional): inserir órgãos ou
                      instituições que financiaram o desenvolvimento do
                      trabalho; (200 caracteres no máximo com espaço.
                    </Item>
                    <Item>
                      E. Comissão de ética (quando aplicável): o nome da
                      comissão e o número de aprovação de ética do trabalho;
                      (150 caracteres no máximo com espaço).
                    </Item>
                    <br />
                    <br />
                  </ItemList>
                </SectionListItem>
              </SectionList>
            </SubSection>
          </ContentLeft>
          <ContentRight>
            <SubSectionTitle>Orientações Gerais - Pôster</SubSectionTitle>

            <SectionList>
              <SectionListItem>
                <strong> 1:</strong> É de total responsabilidade do autor fixar
                o pôster do seu trabalho aprovado, no local indicado pela
                Comissão Científica.
              </SectionListItem>
              <SectionListItem>
                <strong> 2:</strong> O pôster deve possuir dimensões de 90 cm
                (largura) x 120 cm (altura), com texto legível a uma distância
                de pelo menos 1 metro.
              </SectionListItem>
              <SectionListItem>
                <strong> 3:</strong> Deverão constar no pôster as mesmas
                informações enviadas na submissão do trabalho, ou seja, os itens
                a seguir não poderão sofrer alterações:
              </SectionListItem>
            </SectionList>
            <SubSection>
              <SubSectionTitle>
                Normas para confecção dos pôsteres
              </SubSectionTitle>
              <SectionList>
                <SectionListItem>
                  A: Título: Deve ser o mesmo título do resumo aprovado pela
                  Comissão Científica, redigido com letra maiúsculas.
                </SectionListItem>
                <SectionListItem>
                  B: Lista de Autores: Não há o número máximo de autores por
                  resumo, contudo, recomenda-se que todos os autores tenham
                  contribuído de forma significativa com o resumo. Os nomes dos
                  autores devem ser escritos por extenso e completo.
                </SectionListItem>
                <SectionListItem>
                  C: Afiliação dos autores: Deve conter: departamento, setor ou
                  laboratório, nome da instituição, sigla da instituição,
                  cidade, estado (sigla) e país. Exemplo: Departamento de
                  Clínica Veterinária, Faculdade de Medicina Veterinária e
                  Zootecnia de Botucatu – FMVZ/Unesp, Botucatu, SP, Brasil.
                </SectionListItem>
                <SectionListItem>
                  D: Resumo: Deve-se utilizar o mesmo texto aprovado pela
                  Comissão Científica, mas enriquecer o pôster utilizando
                  figuras, fotos, tabelas e gráficos. Os pôsteres devem ser
                  elaborados no modelo do pôster disponibilizado no site da
                  Conferência e conter as subdivisões:
                  <ItemList>
                    <Item>
                      • Trabalhos Científicos: Título, Autores, Filiação dos
                      Autores, Palavras-Chave, Introdução, Material e Métodos,
                      Resultados, Discussão (ou Resultados & Discussão),
                      Conclusões, Agradecimentos e Atestado Comissão de Ética
                      (quando pertinentes) e Referências (inserir as principais
                      referências bibliográficas).
                    </Item>
                  </ItemList>
                </SectionListItem>
                <SectionListItem>
                  E: Será permitido inserir no Pôster:
                  <br />
                  <ItemList>
                    <Item>• Referências utilizadas</Item>
                    <Item>• Figuras, gráficos e/ou tabelas (opcional)</Item>
                    <Item>
                      • Arte: O tipo da fonte deve ser simples, permitindo
                      leitura rápida e à uma distância de 1 metro;
                    </Item>
                    <Item>
                      • Recomenda-se utilizar todos os recursos disponíveis para
                      que o pôster desperte o interesse do público.
                    </Item>
                  </ItemList>
                </SectionListItem>
              </SectionList>
            </SubSection>
          </ContentRight>
        </ContentWrapper>
      </Container>
      <SubSectionTitle style={{ width: "70%", marginLeft: "14%" }}>
        Todo autor ou coautor que submete um resumo tem direito a inscrição e
        participação no evento. Os resumos devem ser submetidos até dia 30 de
        agosto de 2024 pelo email: etectaufu@gmail.com
      </SubSectionTitle>

      <Footer />
    </>
  );
}

export default Submit;
