/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container, Text } from "./styles";

function TagPage({ text, style }) {
  return (
    <Container style={style}>
      <Text>{text}</Text>
    </Container>
  );
}
export default TagPage;
