import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.brancogelo};

  @media (max-width: 700px) {
    height: 70vh;
  }
`;

export const FirstStep = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 700px) {
    margin-top: 40px;
    height: 10%;
    align-self: center;
  }
`;

export const SecondStep = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 90%;
  height: 40%;
  justify-content: center;
  margin-bottom: 2%;
  align-items: center;

  @media (max-width: 700px) {
    height: 20%;
    margin-bottom: 20px;
  }
`;

export const SquareLogo = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 30%;
  height: 50px;
  align-items: center;
  justify-content: center;
  object-fit: contain;

  @media (max-width: 700px) {
    width: 40%;
    height: 30px;
  }
`;

export const Tag = styled.div`
  background-color: blue;
  width: 30vw;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
