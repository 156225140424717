import React from "react";
import Slider from "react-slick";
import {
  Container,
  ContentLeft,
  PhotoGrid,
  Photo,
  GlobalStyle,
} from "./styles";
import images from "../../utils/imageLoader"; // Importe o módulo de carregamento de imagens
import images2016 from "../../utils/imageLoader2016"; // Importe o módulo de carregamento de imagens

function Contact() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <GlobalStyle />

      <Container id="contact">
        <ContentLeft>
          <PhotoGrid>
            <Slider {...settings}>
              {images2016.map((url, index) => (
                <Photo key={index} src={url} />
              ))}
            </Slider>
          </PhotoGrid>
        </ContentLeft>
        <ContentLeft>
          <br></br>
          <PhotoGrid>
            <Slider {...settings}>
              {images.map((url, index) => (
                <Photo key={index} src={url} />
              ))}
            </Slider>
          </PhotoGrid>
        </ContentLeft>
      </Container>
    </>
  );
}

export default Contact;
