import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  flex: 1;
  margin-top: 70px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 10px;

  @media (max-width: 700px) {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;

  img {
    width: 100%;
    height: auto;
    max-width: 50%;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    img {
      max-width: 100%;
      height: auto;
    }
  }
`;
