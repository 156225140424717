import styled, { keyframes } from "styled-components";
import logoFull from "../../assets/logoprincipal.png";

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.header`
  background-color: #cfeeb2;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 180px;
  height: 70px;
  background-image: url(${logoFull});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: -40px;
`;

export const ContainerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Options = styled.a`
  color: black;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 50px;

  text-decoration: none;
  @media (max-width: 700px) {
    margin-right: 10px;
    font-size: 11px;
  }
`;
