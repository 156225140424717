import styled from "styled-components";
import logoFullBlue from "../../assets/logo_white.png";
import theme from "../../styles/theme";

export const Container = styled.header`
  background-color: ${theme.colors.test};
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Mobile */
  @media (max-width: 700px) {
  }
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Mobile */
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 65px;

  /* Mobile */
  @media (max-width: 700px) {
    width: 50%;
    margin-right: 6px;
  }
`;

export const Logo = styled.div`
  width: 190px;
  height: 160px;
  background-image: url(${logoFullBlue});
  background-size: contain;
  background-repeat: no-repeat;
  /* margin-top: 1%:: */
  margin-left: 8%;

  /* Mobile */
  @media (max-width: 700px) {
    margin-left: 0px;
    width: 50%;
    height: 100px;
  }
`;
