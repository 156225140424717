/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Container, ContentArea, Logo, ContainerMenu, Options } from "./styles";
import theme from "../../styles/theme";
import { useNavigate } from "react-router-dom";

function Header() {
  const [isFading, setIsFading] = useState(false);

  const handleButtonClick = () => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSd7i48zOJEtglFRuQEcb_j9T-jP5oTwNmNRIGeny_eN1YOx4g/viewform";
  };

  const handleClickSpeeches = () => {
    setIsFading(true);
    setTimeout(() => {
      navigate("/palestrantes");
      window.location.reload();
    }, 500);
  };

  const handleClick = () => {
    setIsFading(true);
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 500);
  };

  const handleClickSubmission = () => {
    setIsFading(true);
    setTimeout(() => {
      navigate("/submissao-de-trabalhos");
      window.location.reload();
    }, 500);
  };

  const navigate = useNavigate();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/programacao.pdf";
    link.download = "programacao.pdf";
    link.click();
  };

  return (
    <>
      <Container>
        <ContentArea>
          <Logo onClick={handleClick} />
          <ContainerMenu>
            <Options
              style={{ color: `${theme.colors.test}` }}
              onClick={handleClickSubmission}
            >
              Submissão de trabalhos
            </Options>

            <Options
              className={isFading ? "fade-out" : ""}
              style={{ color: `${theme.colors.test}` }}
              onClick={handleClickSpeeches}
            >
              Palestrantes
            </Options>

            <Options
              style={{ color: `${theme.colors.test}` }}
              onClick={handleDownload}
            >
              Programação
            </Options>

            <Options
              style={{ color: `${theme.colors.test}` }}
              onClick={() => handleButtonClick()}
            >
              Inscrições
            </Options>
          </ContainerMenu>
        </ContentArea>
      </Container>
    </>
  );
}

export default Header;
