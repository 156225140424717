/* eslint-disable import/no-anonymous-default-export */
export default {
  colors: {
    primary: "#2854f7",
    beige: "#9eca00",
    secondary: "#073978",
    tertiary: "#D99484",
    quaternary: "#272C57",
    fifty: "#ECEEFF",
    success: "#039403",
    error: "#e74c3c",
    warn: "#F1C40F",
    white: "#FFFFFF",
    black: "#000000",
    darkGray: "##7B7878",
    middleGray: "#EEEEEE",
    lightGray: "#E0E0E0",
    lighterGray: "#F7F8F9",
    disabled: "#3C474B",
    test: "#00583f",
    gray: "#cbcbc7",
    lightBlue: "#0134ce",
    greenClear: "#9fca00",
    beginho: "#c1f18b",
    brancogelo: "#f7f7f7",
  },
  sizes: {
    // PX
    extra: 48,
    extraLarge: 32,
    largeExtra: 24,
    large: 24,
    mediumLarge: 20,
    medium: 16,
    smallMedium: 12,
    small: 8,

    // REM
    rem8: 8,
    rem7: 7,
    rem6: 6,
    rem5: 5,
    rem4: 4,
    rem3: 3,
    rem2_5: 2.5,
    rem2: 2,
    rem1_75: 1.75,
    rem1_5: 1.5,
    rem1_25: 1.25,
    rem1_05: 1.01,
    rem1: 1,
    rem0_75: 0.75,
    rem0_5: 0.5,
    rem0_25: 0.25,
  },
};
