/* eslint-disable react-hooks/exhaustive-deps */
import { Container, SecondStep, SquareLogo } from "./styles";
import logoBiocamp from "../../assets/sponsors/Biocamp.png";
import logoImeve from "../../assets/sponsors/Imeve.png";
import logoAleris from "../../assets/sponsors/aleris.jpg";
import logoExata from "../../assets/sponsors/exata.png";
import logoInata from "../../assets/sponsors/inata.png";
import logoPolinutri from "../../assets/sponsors/polinutri.jpg";
import lallemand from "../../assets/sponsors/lallemand.pdf";
import cargil from "../../assets/sponsors/cargill.jpg";
import planalto from "../../assets/sponsors/planalto.png";
import dms from "../../assets/sponsors/dms.jpg";
import check from "../../assets/sponsors/check.jpg";
import logoPoysel from "../../assets/sponsors/poysel.jpg";
import walpaper3 from "../../assets/walpaper3.jpg";
import React from "react";
import theme from "../../styles/theme";
function Informations() {
  return (
    <>
      <Container id={"contact"}>
        <h2 style={{ color: "black", marginTop: "5px" }}>
          Patrocinadores{" "}
          <span style={{ color: `${theme.colors.test}` }}>Ouro</span>
        </h2>
        <SecondStep>
          <SquareLogo
            src={`${lallemand}`}
            style={{ width: "120px", height: "100px" }}
          />
        </SecondStep>
        <h2 style={{ color: "black", marginTop: "-10px" }}>
          Patrocinadores{" "}
          <span style={{ color: `${theme.colors.test}` }}>Prata</span>
        </h2>
        <SecondStep>
          <SquareLogo
            src={`${logoBiocamp}`}
            style={{ width: "70px", height: "70px" }}
          />
          <SquareLogo src={`${logoPoysel}`} />
          <SquareLogo src={`${check}`} />
        </SecondStep>
        <h2 style={{ color: "black", marginTop: "-20px" }}>
          Patrocinadores{" "}
          <span style={{ color: `${theme.colors.test}` }}>Bronze</span>
        </h2>
        <SecondStep style={{ marginTop: "25px" }}>
          <SquareLogo src={`${logoImeve}`} />
          <SquareLogo src={`${logoAleris}`} />
          <SquareLogo src={`${logoExata}`} />
          <SquareLogo src={`${logoInata}`} />
          <SquareLogo src={`${logoPolinutri}`} />
          <SquareLogo src={`${planalto}`} />
          <SquareLogo src={`${cargil}`} />
          <SquareLogo src={`${dms}`} />
        </SecondStep>
      </Container>
      <img
        src={walpaper3}
        alt="Descrição da imagem"
        style={{
          width: "100%",
          height: "35%",
        }}
      />
    </>
  );
}

export default Informations;
