import styled, { createGlobalStyle } from "styled-components";
import theme from "../../styles/theme";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: Arial, sans-serif;
    color: white;
    margin: 0;
    padding: 0;
    /* background-color: ${theme.colors.test}; */
  }
`;

export const Container = styled.div`
  display: flex;
  /* background-color: ${theme.colors.darkGray}; */
  width: 100vw;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: 500px; */
  /* Mobile */
  @media (max-width: 700px) {
    margin-top: -50px;
  }
`;

export const ContentLeft = styled.div`
  width: 80%;
  text-align: center;
  margin-top: 2rem;
  position: relative; /* Para permitir o posicionamento absoluto das setas */
  overflow: visible; /* Garante que as setas fora do carrossel ainda sejam visíveis */

  .slick-prev,
  .slick-next {
    z-index: 1;
    /* background: red; Ajuste a cor conforme necessário */
    /* border: 5px solid black; Adiciona uma borda grande */
    /* border-radius: 50%; Torna a borda arredondada */
    width: 50px; /* Ajuste a largura conforme necessário */
    height: 50px; /* Ajuste a altura conforme necessário */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px; /* Ajuste o tamanho do ícone da seta */
    color: grey; /* Mude a cor do ícone conforme necessário */
  }

  .slick-prev {
    left: -40px; /* Ajuste a posição à esquerda conforme necessário */
  }

  .slick-next {
    right: -40px; /* Ajuste a posição à direita conforme necessário */
  }

  .slick-dots {
    display: none;
  }

  /* Mobile */
  @media (max-width: 700px) {
    margin-top: 20px;

    .slick-dots {
      bottom: -20px; /* Ajuste a posição dos dots conforme necessário */
    }
  }
`;
export const PhotoGrid = styled.div`
  .slick-slide > div {
    padding: 0 10px; // Adiciona padding entre as fotos
  }
`;

export const Photo = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  height: 200px;
  cursor: pointer;

  @media (max-width: 700px) {
    height: 150px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;
