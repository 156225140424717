import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.header`
  background-color: ${theme.colors.test};
  width: 30vw;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Adicionando media queries para tornar o componente responsivo */
  @media (max-width: 1200px) {
    width: 40vw;
  }

  @media (max-width: 992px) {
    width: 50vw;
  }

  @media (max-width: 768px) {
    width: 60vw;
    height: 10vh;
  }

  @media (max-width: 576px) {
    width: 80vw;
    height: 12vh;
  }
`;

export const Text = styled.h2`
  color: ${theme.colors.white}; /* Define a cor do texto */

  /* Adicionando media queries para ajustar o tamanho do texto */
  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }

  @media (max-width: 992px) {
    font-size: 1.25rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
`;
